exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-big-data-analysis-startup-js": () => import("./../../../src/pages/big-data-analysis-startup.js" /* webpackChunkName: "component---src-pages-big-data-analysis-startup-js" */),
  "component---src-pages-capabilities-allservices-js": () => import("./../../../src/pages/capabilities/allservices.js" /* webpackChunkName: "component---src-pages-capabilities-allservices-js" */),
  "component---src-pages-capabilities-crm-js": () => import("./../../../src/pages/capabilities/crm.js" /* webpackChunkName: "component---src-pages-capabilities-crm-js" */),
  "component---src-pages-capabilities-devops-js": () => import("./../../../src/pages/capabilities/devops.js" /* webpackChunkName: "component---src-pages-capabilities-devops-js" */),
  "component---src-pages-capabilities-digital-js": () => import("./../../../src/pages/capabilities/digital.js" /* webpackChunkName: "component---src-pages-capabilities-digital-js" */),
  "component---src-pages-capabilities-ecommerce-js": () => import("./../../../src/pages/capabilities/ecommerce.js" /* webpackChunkName: "component---src-pages-capabilities-ecommerce-js" */),
  "component---src-pages-capabilities-erp-js": () => import("./../../../src/pages/capabilities/erp.js" /* webpackChunkName: "component---src-pages-capabilities-erp-js" */),
  "component---src-pages-capabilities-marketplace-js": () => import("./../../../src/pages/capabilities/marketplace.js" /* webpackChunkName: "component---src-pages-capabilities-marketplace-js" */),
  "component---src-pages-capabilities-webdev-js": () => import("./../../../src/pages/capabilities/webdev.js" /* webpackChunkName: "component---src-pages-capabilities-webdev-js" */),
  "component---src-pages-careers-job-1-js": () => import("./../../../src/pages/careers/job1.js" /* webpackChunkName: "component---src-pages-careers-job-1-js" */),
  "component---src-pages-careers-jobs-js": () => import("./../../../src/pages/careers/jobs.js" /* webpackChunkName: "component---src-pages-careers-jobs-js" */),
  "component---src-pages-case-studies-details-js": () => import("./../../../src/pages/case-studies-details.js" /* webpackChunkName: "component---src-pages-case-studies-details-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-consultation-js": () => import("./../../../src/pages/consultation.js" /* webpackChunkName: "component---src-pages-consultation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-details-js": () => import("./../../../src/pages/course-details.js" /* webpackChunkName: "component---src-pages-course-details-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-data-analytics-ai-startup-js": () => import("./../../../src/pages/data-analytics-ai-startup.js" /* webpackChunkName: "component---src-pages-data-analytics-ai-startup-js" */),
  "component---src-pages-data-analytics-ml-consulting-js": () => import("./../../../src/pages/data-analytics-ml-consulting.js" /* webpackChunkName: "component---src-pages-data-analytics-ml-consulting-js" */),
  "component---src-pages-data-science-ml-company-js": () => import("./../../../src/pages/data-science-ml-company.js" /* webpackChunkName: "component---src-pages-data-science-ml-company-js" */),
  "component---src-pages-data-science-online-courses-js": () => import("./../../../src/pages/data-science-online-courses.js" /* webpackChunkName: "component---src-pages-data-science-online-courses-js" */),
  "component---src-pages-digital-marketing-agency-js": () => import("./../../../src/pages/digital-marketing-agency.js" /* webpackChunkName: "component---src-pages-digital-marketing-agency-js" */),
  "component---src-pages-event-details-js": () => import("./../../../src/pages/event-details.js" /* webpackChunkName: "component---src-pages-event-details-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gmc-jsx": () => import("./../../../src/pages/GMC.jsx" /* webpackChunkName: "component---src-pages-gmc-jsx" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-insights-all-js": () => import("./../../../src/pages/insights/all.js" /* webpackChunkName: "component---src-pages-insights-all-js" */),
  "component---src-pages-insights-blogs-js": () => import("./../../../src/pages/insights/blogs.js" /* webpackChunkName: "component---src-pages-insights-blogs-js" */),
  "component---src-pages-insights-emodels-js": () => import("./../../../src/pages/insights/emodels.js" /* webpackChunkName: "component---src-pages-insights-emodels-js" */),
  "component---src-pages-insights-headless-js": () => import("./../../../src/pages/insights/headless.js" /* webpackChunkName: "component---src-pages-insights-headless-js" */),
  "component---src-pages-insights-objectives-js": () => import("./../../../src/pages/insights/objectives.js" /* webpackChunkName: "component---src-pages-insights-objectives-js" */),
  "component---src-pages-insights-purpose-js": () => import("./../../../src/pages/insights/purpose.js" /* webpackChunkName: "component---src-pages-insights-purpose-js" */),
  "component---src-pages-insights-soon-js": () => import("./../../../src/pages/insights-soon.js" /* webpackChunkName: "component---src-pages-insights-soon-js" */),
  "component---src-pages-machine-learning-ai-solutions-js": () => import("./../../../src/pages/machine-learning-ai-solutions.js" /* webpackChunkName: "component---src-pages-machine-learning-ai-solutions-js" */),
  "component---src-pages-membership-levels-js": () => import("./../../../src/pages/membership-levels.js" /* webpackChunkName: "component---src-pages-membership-levels-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-authentication-js": () => import("./../../../src/pages/profile-authentication.js" /* webpackChunkName: "component---src-pages-profile-authentication-js" */),
  "component---src-pages-seo-agency-js": () => import("./../../../src/pages/seo-agency.js" /* webpackChunkName: "component---src-pages-seo-agency-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sme-jsx": () => import("./../../../src/pages/sme.jsx" /* webpackChunkName: "component---src-pages-sme-jsx" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

